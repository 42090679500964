import { DiscountManager, DiscountManagerImpl } from "@utils/Discount/DiscountManager";
import { FeeManager, FeeManagerImpl } from "@utils/Discount/FeeManager";
import { User } from "@utils/User/User";
import { DiscountRepositoryImpl } from "@utils/Discount/DiscountRepository";
import { Retailer, RetailerImpl, RetailerJSONObject } from "@utils/Retailer/Retailer";
import { ResponseStatus } from "@utils/OldService/enums";

export interface DiscountCodeInfo {
  code: string;
  retailer?: RetailerJSONObject;
  surchargePercentage: number;
  feePercentage: number;
  discountPercentage: number;
  priceLevel: number;
  enabled: boolean;
  visible: boolean;
}

export interface DiscountCode extends DiscountCodeInfo {
  get feeManager(): FeeManager;

  get discountManager(): DiscountManager;

  get code(): string;

  get retailer(): Retailer | undefined;

  get surchargePercentage(): number;

  get feePercentage(): number;

  get discountPercentage(): number;

  get priceLevel(): number;

  get enabled(): boolean;

  get visible(): boolean;

  updateFromData(data: DiscountCodeInfo): void;

  updateFromUser(user: User): void;

  toJSON(): DiscountCodeInfo;
}

export class DiscountCodeImpl implements DiscountCode {
  private readonly _feeManager: FeeManager;
  private readonly _discountManager: DiscountManager;
  private _discountCode: string = "";
  private _retailer: Retailer | undefined;
  private _surchargePercentage = 0;
  private _feePercentage = 0;
  private _discountPercentage = 0;
  private _priceLevel = 0;
  private _enabled = false;
  private _visible = false;

  constructor(discountCode?: string, onFetched?: () => void) {
    // If discount code is of type string, then fetch the discount code from the database
    if (typeof discountCode === "string") {
      this._discountCode = discountCode;
      const discountRepository = new DiscountRepositoryImpl();

      discountRepository.get({ discountCode: this._discountCode }).then((result) => {
        if (result?.status === ResponseStatus.Success && result.data) {
          const discount = result.data;

          this.updateFromData({
            code: discount.code,
            retailer: discount.retailer,
            surchargePercentage: discount.surcharge,
            feePercentage: discount.fee,
            discountPercentage: discount.discount,
            priceLevel: discount.retailer.price_type?.priceLevel || 5,
            enabled: discount.enabled,
            visible: true
          });
        }
        onFetched?.();
      });
    }

    this._feeManager = new FeeManagerImpl(this);
    this._discountManager = new DiscountManagerImpl(this, this._feeManager, onFetched);
  }

  get feeManager(): FeeManager {
    return this._feeManager;
  }

  get discountManager(): DiscountManager {
    return this._discountManager;
  }

  get code(): string {
    return this._discountCode;
  }

  get retailer(): Retailer | undefined {
    return this._retailer;
  }

  get surchargePercentage(): number {
    return this._surchargePercentage;
  }

  get feePercentage(): number {
    return this._feePercentage;
  }

  get discountPercentage(): number {
    return this._discountPercentage;
  }

  get priceLevel(): number {
    return this._priceLevel;
  }

  get enabled(): boolean {
    return this._enabled;
  }

  get visible(): boolean {
    return this._visible;
  }

  updateFromData(data: DiscountCodeInfo): void {
    this._discountCode = data.code;
    this._retailer = new RetailerImpl({
      id: data.retailer?.id || 0,
      name: data.retailer?.name || "",
      price_type: data.retailer?.price_type || undefined,
      hidden: data.retailer?.hidden || false,
      showPrice: data.retailer?.showPrice || false,
      forceDiscountCode: data.retailer?.forceDiscountCode || false,
      location: data.retailer?.location || undefined
    });
    this._surchargePercentage = data.surchargePercentage;
    this._feePercentage = data.feePercentage;
    this._discountPercentage = data.discountPercentage;
    this._priceLevel = data.priceLevel;
    this._enabled = data.enabled;
    this._visible = data.visible;
  }

  updateFromUser(user: User): void {
    this._discountPercentage = user.discount || 0;
    this._feePercentage = 0;
    this._surchargePercentage = 0;
    this._priceLevel = user.priceLevel;
    this._visible = false;
    this._enabled = user.customerProfile?.showPrices || false;
  }

  toJSON(): DiscountCodeInfo {
    return {
      code: this.code,
      retailer: this.retailer?.toJSON(),
      surchargePercentage: this.surchargePercentage,
      feePercentage: this.feePercentage,
      discountPercentage: this.discountPercentage,
      priceLevel: this.priceLevel,
      enabled: this.enabled,
      visible: this.visible
    };
  }
}
