import * as Sentry from "@sentry/nextjs";

import { RetailerData } from "@utils/Retailer/RetailerRepository";
import { ResponseStatus } from "@utils/Mail/EmailHandler";

export interface DiscountRepository {
  get({ discountCode }: { discountCode?: string }): Promise<
    | {
        status: ResponseStatus;
        data?: DiscountData;
        error?: { message: string };
      }
    | undefined
  >;
}

export interface DiscountData {
  id: number;
  retailer: RetailerData;
  code: string;
  surcharge: number;
  fee: number;
  discount: number;
  enabled: boolean;
}

export class DiscountRepositoryImpl implements DiscountRepository {
  _jwt: string | undefined;

  constructor(jwt?: string) {
    this._jwt = jwt;
  }

  async get({ discountCode }: { discountCode?: string }): Promise<
    | {
        status: ResponseStatus;
        data?: DiscountData;
        error?: { message: string };
      }
    | undefined
  > {
    try {
      const response = await fetch(`/api/discount/${discountCode}`);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (e) {
      Sentry.captureException(e);
      return;
    }
  }
}
